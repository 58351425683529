import { getFluxImageUrl, getBaseUrl } from '@utils/utils';
import { HotelBoxData, CommonHotelBoxData } from '@interfaces/components/hotelBoxData';
import { BestPackageOfferForHotelList } from '@interfaces/api/v1-best-package-offer-for-hotel-list';
import { BestAccommodationOfferForHotelList } from '@interfaces/api/v1-best-accommodation-offer-for-hotel-list';
import boardTypes from '@/js/data/board-types';
import { ApiDataTypes } from '@components/common/types';
import { mapper } from '@services/transform';

interface LinkParams {
	ddate: string | 0 | null,
	rdate?: string | 0 | null,
	adult?: number,
	dur?: string | number,
	depap?: string[],
	dfl?: 1,
	brand?: string,
	board?: string,
}

const baseBaseUrl = getBaseUrl();
const imageBaseUrl = getFluxImageUrl() || 'https://images.flux.reisen';

const transformBoarding = (boardType: string): string => boardTypes.find((type: { value: string }) => type.value === boardType)?.label || '';

export const buildLinkParams = (apiParams: ApiDataTypes | false, hotelOnly = false): string => {
	if (!apiParams) {
		return '';
	}

	const minDur = apiParams.MinDuration,
		maxDur = apiParams.MaxDuration,
		params: LinkParams = {
			ddate: apiParams.RelativeStartDate || apiParams.StartDate,
			rdate: apiParams.RelativeEndDate || apiParams.EndDate,
			adult: apiParams.Adults,
			dur: minDur !== maxDur ? `${minDur},${maxDur}` : maxDur,
			depap: apiParams.DepartureAirport,
			...(apiParams.DirectFlight ? { dfl: 1 } : {}),
			...(apiParams.TourOperatorCodes?.length ? { brand: apiParams.TourOperatorCodes?.join(',') } : {}),
			...(apiParams.BoardTypes?.length ? { board: mapper(apiParams.BoardTypes, boardTypes, (obj) => obj.showInMenu) } : {}),
			...(apiParams.Children?.length ? { child: apiParams.Children?.join('-') } : {}),
		};
	let paramsString = '?';

	const paramsArray = Object.entries(params);

	paramsArray.forEach(function(keyValue) {
		paramsString += `${keyValue.join('=')}&`;
	});

	paramsString += hotelOnly ? 'ibe=hotel' : 'ibe=package';

	return paramsString;
};

const commonProperties = (offer: BestPackageOfferForHotelList | BestAccommodationOfferForHotelList): CommonHotelBoxData => {
	const hotel = offer.Hotel,
		location = offer.Location;

	return {
		Name: hotel.Name,
		HotelIffCode: hotel.IffCode,
		CityName: location.City.CityName,
		RegionName: location.Region.RegionName,
		CountryName: location.Country.CountryName,
		Category: hotel.Category,
		MeanRatingOverall: offer.Ratings.Overall,
		Image: {
			l: `${imageBaseUrl}/hotel/${hotel.IffCode}/1/l`,
			m: `${imageBaseUrl}/hotel/${hotel.IffCode}/1/m`,
			s: `${imageBaseUrl}/hotel/${hotel.IffCode}/1/s`,
			xs: `${imageBaseUrl}/hotel/${hotel.IffCode}/1/xs`
		}
	};
};

const transformToPackageOffer = (offer: BestPackageOfferForHotelList, apiParams: ApiDataTypes | false, fromPlugin = true): HotelBoxData => {
	const packageOffer = offer.PackageOffer;
	let url = `${baseBaseUrl}${offer.Hotel.URL}`;

	// Don't append query string on auto generated pages
	if (fromPlugin) {
		url += buildLinkParams(apiParams) || '';
	}
	return {
		...commonProperties(offer),
		Url: url,
		Price: packageOffer.Price,
		CurrencyCode: packageOffer.CurrencyCode,
		BoardType: transformBoarding(packageOffer.Board),
		TravelDuration: packageOffer.BetweenDeparturesDuration,
		DepartureAirportTown: packageOffer.Flight.OutboundFlight.AirportName,
		DepartureDay: packageOffer.StartDate,
	};
};
const transformToAccommodationOffer = (offer: BestAccommodationOfferForHotelList, apiParams: ApiDataTypes | false, fromPlugin = true): HotelBoxData => {
	const hotelOffer = offer.AccommodationOffer;
	let url = `${baseBaseUrl}${offer.Hotel.URL}`;

	if (fromPlugin) {
		url += buildLinkParams(apiParams, true) || '';
	}
	return {
		...commonProperties(offer),
		Url: url,
		Price: hotelOffer.Price,
		CurrencyCode: hotelOffer.CurrencyCode,
		BoardType: transformBoarding(hotelOffer.Board),
		TravelDuration: hotelOffer.OvernightStays,
		DepartureDay: hotelOffer.StartDate,
	};
};

export const transformList = (
	hotelOffers: BestPackageOfferForHotelList[] | BestAccommodationOfferForHotelList[],
	hotelOnly = false,
	apiParams: ApiDataTypes | false,
	fromPlugin = true
): HotelBoxData[] => {
	let data: HotelBoxData[];

	if (hotelOnly) {
		data = hotelOffers.map((offer) => transformToAccommodationOffer(offer as BestAccommodationOfferForHotelList, apiParams, fromPlugin));
	} else {
		data = hotelOffers.map((offer) => transformToPackageOffer(offer as BestPackageOfferForHotelList, apiParams, fromPlugin));
	}

	return data;
};

export const buildListUrl = (apiParams: ApiDataTypes | false, id: number, destinationType: string, hotelOnly = false): string => {
	const destionationTypes = {
		country: `/hotels/g/${id}/`,
		region: `/hotels/r/${id}/`,
		city: `/hotels/o/${id}/`
	};

	const linkParams = buildLinkParams(apiParams, hotelOnly);

	return (destionationTypes[destinationType as keyof typeof destionationTypes]) + linkParams;
};
