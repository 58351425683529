import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "banner__inner" }
const _hoisted_2 = ["href", "title"]
const _hoisted_3 = { class: "banner__text" }
const _hoisted_4 = { class: "banner__subtext" }
const _hoisted_5 = ["title"]

import { ref } from 'vue';
import { getLocaleString } from '@utils/utils';
import { EventBus } from '@global-js/event-bus';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';

interface Props {
	text: string,
	subtext: string,
	link: string,
	show?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'BannerTop',
  props: {
    text: {},
    subtext: {},
    link: {},
    show: { type: Boolean, default: false }
  },
  emits: ['BannerTop:Close'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const showBanner = ref(props.show);
const localeString = getLocaleString('close');
const emit = __emit;

const closeBanner = () => {
	emit('BannerTop:Close');
	showBanner.value = false;
	EventBus.$emit('BannerTop:Close');
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["banner", { 'banner--hidden': !showBanner.value }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("a", {
        class: "banner__info-block",
        href: _ctx.link,
        rel: "noopener",
        title: _ctx.text
      }, [
        _createVNode(BaseIcon, {
          name: "infoFill",
          class: "banner__info-icon"
        }),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.text), 1 /* TEXT */),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.subtext), 1 /* TEXT */)
      ], 8 /* PROPS */, _hoisted_2),
      _createElementVNode("button", {
        class: "banner__button",
        title: _unref(localeString),
        onClick: _withModifiers(closeBanner, ["stop","prevent"])
      }, [
        _createVNode(BaseIcon, {
          name: "close",
          class: "banner__close-icon"
        })
      ], 8 /* PROPS */, _hoisted_5)
    ])
  ], 2 /* CLASS */))
}
}

})