<template>
	<section class="favorited-hotel-list__wrapper">
		<div
			v-if="hotels.length > 0"
		>
			<TransitionGroup
				name="list"
				tag="ul"
				class="favorited-hotel-list__listing"
			>
				<FavoritedHotelItem
					v-for="h in hotels"
					:key="h.IffCode"
					:iff-code="h.IffCode"
					:title="h.Name"
					:url="'/hotel/' + h.IffCode "
					:region="h.Region"
					:hotel-rating="h.Category"
					:show-suns="showSuns"
					@FavoritedHotelItem:Delete="onDelete(h)"
				/>
			</TransitionGroup>
		</div>
		<div
			v-else
			class="favorited-hotel-list__no-items"
		>
			Sie haben noch nichts in dem Merkzettel gespeichert.
		</div>
	</section>
</template>

<script lang="ts" setup>
import FavoritedHotelItem from '@lmt-rpb/FavoritedHotelItem/FavoritedHotelItem.vue';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';

interface Props {
	hotels: FavoritedHotel[];
	showSuns: boolean,
}
const emit = defineEmits(['FavoritedHotelList:Delete']);

withDefaults(defineProps<Props>(), {
	showSuns: false,
});

const onDelete = (hotel: FavoritedHotel): void => {
	emit('FavoritedHotelList:Delete', hotel);
};

</script>

<style lang="scss" scoped>
.favorited-hotel-list__wrapper {
	padding: 2.3rem 1.4rem;
	height: calc(100vh - 5.8rem);
	overflow-y: auto;
}

.favorited-hotel-list__listing {
	display: inline-flex;
	justify-content: flex-start;
	flex-direction: column;
	padding-inline-start: 0;
	margin: 0;
	gap: 2.3rem;
	min-height: 12rem;
	width: 100%;
	padding-bottom: 3rem;
}

.favorited-hotel-list__no-items {
	font-family: $font-family;
	font-size: $font-small-1;
	height: 19rem;
	justify-content: center;
	display: flex;
	align-items: center;
}

.favorited-hotel-list__wrapper,
.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
	transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
	opacity: 0;
	transform: translateX(3rem);
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

@media (min-width: $breakpoint-verysmall) {
	.favorited-hotel-list__wrapper {
		padding: 2.3rem 3.4rem;
	}
}

@media (min-width: $breakpoint-small) {
	.favorited-hotel-list__listing {
		max-width: 150rem;
		padding-bottom: 0;
	}

	.favorited-hotel-list__no-items {
		max-width: 45.7rem;
	}

	.favorited-hotel-list__wrapper {
		// uppercase min to bypass sass compiler
		max-height: MIN(83.8rem, calc(100vh - 5.8rem));
		height: auto;
	}
}
</style>
