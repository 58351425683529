import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header-favorite-list-wrapper" }

import { determineClient } from '@utils/utils';
import FavoritedHotelList from '@lmt-rpb/FavouritedHotelList/FavoritedHotelList.vue';
import BookmarkAnalytics from '@/js/modules/analytics/BookmarkAnalytics';
import { FavoritedHotel } from '@/interfaces/api/v1-hotel-list';
import { useStore } from '@/components/common/store';
import { computed } from 'vue';
import { removeEntryFromFavoriteCookie } from '../../js/modules/bookmark';

interface Props {
	showDropdown: boolean
	hotels: FavoritedHotel[]
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderFavoriteDropdown',
  props: {
    showDropdown: { type: Boolean, default: false },
    hotels: {}
  },
  setup(__props: any) {



const store = useStore();

const showSuns = computed((): boolean => determineClient(store.state.config.baseUrl) === 'com');

const removeFavorite = (removeHotel: FavoritedHotel) => {
	const item = BookmarkAnalytics.fromFavoritedHotel([removeHotel]);
	removeEntryFromFavoriteCookie(item);
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-favorite-dropdown", {'header-favorite-dropdown--show': _ctx.showDropdown}])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(FavoritedHotelList, {
        hotels: _ctx.hotels,
        "show-suns": showSuns.value,
        "onFavoritedHotelList:Delete": removeFavorite
      }, null, 8 /* PROPS */, ["hotels", "show-suns"])
    ])
  ], 2 /* CLASS */))
}
}

})