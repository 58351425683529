import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "usp rpb_container" }
const _hoisted_2 = { class: "usp__content-container" }
const _hoisted_3 = { class: "usp__inner-content" }
const _hoisted_4 = { class: "usp__image" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "usp__inner-content-box" }
const _hoisted_7 = { class: "usp__textcontent" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import type { USPData } from '@/interfaces/usp-data-type';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';


export default /*@__PURE__*/_defineComponent({
  __name: 'Usp',
  props: {
    uspData: {},
    title: {},
    subtitle: {},
    headerLink: {},
    headerLayout: {}
  },
  setup(__props: any) {

const props = __props;

const headerProps: HeaderProps = {
	title: props.title,
	subtitle: props.subtitle,
	mobileTitle: props.title,
	mobileSubtitle: props.subtitle,
	link: props.headerLink
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ComponentHeader, {
      "header-props": headerProps,
      class: _normalizeClass('usp__header')
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.uspData, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "usp__element"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(item.link && item.link != ''?'a':'div'), {
            href: item.link,
            class: "usp__link-wrapper",
            "data-ga-event": "select_content",
            "data-ga-content_type": "USP",
            "data-ga-item_id": item.title
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (item.image)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: item.image,
                        alt: item.altTextImage
                      }, null, 8 /* PROPS */, _hoisted_5))
                    : _createCommentVNode("v-if", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (item.title)
                      ? (_openBlock(), _createElementBlock("h4", _hoisted_8, _toDisplayString(item.title), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true),
                    (item.body)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(item.body), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  (item.link)
                    ? (_openBlock(), _createBlock(BaseIcon, {
                        key: 0,
                        name: "chevronRight",
                        class: "usp__icon"
                      }))
                    : _createCommentVNode("v-if", true)
                ])
              ])
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["href", "data-ga-item_id"]))
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}
}

})