import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loading__headlines"
}
const _hoisted_2 = {
  key: 2,
  class: "loading__container"
}

import OfferGrid from '@lmt-rpb/OfferGrid/OfferGrid.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import axios, { AxiosError } from 'axios';
import request from '@components/common/services/request';
import { determineClient, getLocaleString } from '@utils/utils';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import { ApiDataTypes } from '@components/common/types/index';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import { EventBus } from '@global-js/event-bus';
import { BestPackageOfferForHotelListResponse } from '@/interfaces/api/v1-best-package-offer-for-hotel-list';
import { useStore } from '@/components/common/store';
import { computed, onMounted, ref } from 'vue';
import { transformList, buildListUrl } from './hotelGridHelper';

interface Props {
	fromPlugin: boolean,
	hotelGridOffers: HotelBoxData[],
	bodyParams: ApiDataTypes,
	hotelOnly: boolean,
	apiUrl: string,
	uniqueKey: string,
	destinationName?: string,
	moreLink: string,
	headerProps: HeaderProps,
	hotelBoxAmount?: number,
	fourColumns?: boolean,
	isAutoGeneratedTopic?: boolean,
	queryString?: string,
	isSlider?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HotelGrid',
  props: {
    fromPlugin: { type: Boolean },
    hotelGridOffers: {},
    bodyParams: {},
    hotelOnly: { type: Boolean },
    apiUrl: {},
    uniqueKey: {},
    destinationName: { default: '' },
    moreLink: {},
    headerProps: {},
    hotelBoxAmount: { default: 3 },
    fourColumns: { type: Boolean, default: false },
    isAutoGeneratedTopic: { type: Boolean, default: false },
    queryString: { default: '' },
    isSlider: { type: Boolean, default: true }
  },
  setup(__props: any) {

const store = useStore();

const props = __props;

const headerPropsClone = ref<HeaderProps>(props.headerProps);

const moreLinkClone = ref<string>(props.moreLink);

const items = ref<HotelBoxData[] | never[]>([]);

const error = ref(false);

const loading = ref(true);

const currencySymbol = ref(getLocaleString('currency'));

const showSuns = ref(determineClient(window.location.href) === 'com');

const destinationType = computed((): string => {
	const destinationTypes = {
		page_country: 'country',
		page_region: 'region',
		page_city: 'city',
		page_topic_regiongroup: 'country',
		page_topic_topregion: 'region',
	};
	const bodyClassList = document.body.classList;
	let type = '';

	Object.keys(destinationTypes).forEach((pageClass) => {
		if (bodyClassList.contains(pageClass)) {
			type = destinationTypes[pageClass as keyof typeof destinationTypes];
		}
	});

	return type;
});

const fetchOffers = (): void => {
	loading.value = true;
	error.value = false;
	request<BestPackageOfferForHotelListResponse>({ method: 'post', url: props.apiUrl, data: props.bodyParams }, props.uniqueKey)
		.then((resp) => {
			if (!resp || Object.keys(resp).length === 0) {
				return;
			}
			items.value = transformList(resp.Offers, props.hotelOnly, props.bodyParams, false);
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('HotelGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			loading.value = false;
		});
};

const updateHeaderPropsClone = (): void => {
	let newListUrl = buildListUrl(
		false, // Don't append query string on auto generated 'Urlaub' or 'Reiseziel' pages
		store.state.searchMask?.destination?.id as number,
		destinationType.value,
		props.hotelOnly
	);
	const params = props.queryString ? `?${props.queryString}` : '';
	if (props.isAutoGeneratedTopic) newListUrl = `${newListUrl}${params}`;

	headerPropsClone.value = {
		...props.headerProps,
		link: newListUrl
	};
	moreLinkClone.value = newListUrl;
};

onMounted((): void => {
	if (props.fromPlugin) {
		// hotelgrid offers from plugin already transformed
		items.value = props.hotelGridOffers;
		loading.value = false;

		// Append url search parameter on topic pages
		if (props.isAutoGeneratedTopic) {
			updateHeaderPropsClone();
		}
	} else {
		const destinationId = store?.state?.searchMask?.destination?.id;
		if (!destinationId) {
			EventBus.$once('Autocomplete:updatedActiveItem', updateHeaderPropsClone);
		} else {
			updateHeaderPropsClone();
		}

		fetchOffers();
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!loading.value && !error.value && items.value.length)
      ? (_openBlock(), _createBlock(OfferGrid, {
          key: 0,
          "hotel-list": items.value,
          slider: _ctx.isSlider,
          "currency-symbol": currencySymbol.value,
          "show-suns": showSuns.value,
          "show-bottom-c-t-a": false,
          "destination-name": _ctx.destinationName,
          "header-props": headerPropsClone.value,
          "more-link": moreLinkClone.value,
          "four-columns": _ctx.fourColumns
        }, null, 8 /* PROPS */, ["hotel-list", "slider", "currency-symbol", "show-suns", "destination-name", "header-props", "more-link", "four-columns"]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(SkeletonLoader, {
            "width-unit": 'rem',
            "loader-width": 30,
            "loader-height": 7,
            "border-radius": '0.5rem',
            class: "loading__headline"
          }),
          _createVNode(SkeletonLoader, {
            "width-unit": 'rem',
            "loader-width": 16,
            "loader-height": 3,
            "border-radius": '0.5rem',
            class: "loading__more-link"
          })
        ]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hotelBoxAmount, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "loading__item"
            }, [
              _createVNode(SkeletonLoader, {
                "width-unit": '%',
                "loader-width": 100,
                "loader-height": 44.6,
                "border-radius": '1.2rem'
              })
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})