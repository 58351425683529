import { parseSearchMaskAndFilterSettings } from '@components/common/services/transform';
import { createApp, h } from 'vue';
import { determineClient } from '@utils/utils';
import ThemePageSearch from '@lmt-rpb/ThemePageSearch/ThemePageSearch.vue';
import { EventBus } from '@global-js/event-bus';
import * as typoSearchParamService from '@components/common/services/typoSearchParamService';
import { useStore } from '@components/common/store';

const store = useStore();
// eslint-disable-next-line no-use-before-define
initSearch();

/**
 * Mounts searchmask
 * @param mountPoint HTMLElement - the element for root to mount
 * @param isHeaderSearch boolean - search in the header
 */
async function mountSearch(mountPoint: HTMLElement) {
	try {
		await initStore();
	} catch (e) {
		console.error(e);
	}
	const isAutoGeneratedTopic = document.body.classList.contains('page_topic');

	const Search = createApp({
		render: () => h(ThemePageSearch, {
			client: determineClient(document.location.href),
			images: mountPoint.dataset.images ? JSON.parse(mountPoint.dataset.images) : null,
		}),
	});

	Search.mount(mountPoint);

	if (isAutoGeneratedTopic) EventBus.$emit('Autocomplete:updatedActiveItem'); // Init fixed button
}

function initSearch() {
	const mountPoint = document.querySelector('#search-form__main');
	if (mountPoint) {
		mountSearch(mountPoint as HTMLElement);
	}
}

async function initStore() {
	typoSearchParamService.initStore('#search-mask-data');
	updateTopicPageStoreByQuery();
}

// move to a topic page js file since is specific to it.
function updateTopicPageStoreByQuery() {
	const urlDataSelector = (document.querySelector('.url-params-data') as HTMLElement)?.dataset;
	const parsedUrlParams = parseSearchMaskAndFilterSettings(urlDataSelector ?? {}).urlParams;
	if (parsedUrlParams) {
		store.commit('searchMask/updateFormData', parsedUrlParams);
	}
}
