<template>
	<li
		class="favorited-hotel"
	>
		<a
			class="favorited-hotel__link"
			:href="url"
			rel="nofollow"
		>
			<img
				class="favorited-hotel__img"
				:src="smallImage"
				:alt="title"
			/>
			<div class="favorited-hotel__info">
				<div class="favorited-hotel__heading-wrapper">
					<h3 class="favorited-hotel__heading">
						{{ title }}
					</h3>
					<button
						class="favorited-hotel__close-button"
						@click.prevent.stop="$emit('FavoritedHotelItem:Delete')"
					>
						<base-icon
							name="closeCircle"
							class="favorited-hotel__icon"
						/>
					</button>
				</div>
				<HotelStars
					class="favorited-hotel__rating"
					:rating="hotelRating"
					:hotel-box="false"
					:show-suns="showSuns"
				/>
				<span class="favorited-hotel__region">{{ region }}</span>
			</div>
		</a>
	</li>
</template>

<script lang="ts" setup>
import HotelStars from '@lmt-rpb/HotelStars/HotelStars.vue';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import { getFluxImageUrl } from '@/js/utils/utils';
import { computed } from 'vue';

interface Props {
	title: string,
	iffCode: number,
	url: string,
	region: string,
	hotelRating: number,
	showSuns?: boolean,
}

const props = defineProps<Props>();

const imageUrl = computed(() => {
	const url = getFluxImageUrl();
	return !url ? 'https://images.flux.reisen' : url;
});

const smallImage = computed(() => `${imageUrl.value}/hotel/${props.iffCode}/1/s`);

</script>

<style lang="scss" scoped>
.favorited-hotel {
	list-style: none;
	height: 12.4rem;
	background-color: $color-page-background;
	box-shadow: 0.1rem 0.1rem 0.8rem $color-black-t15;
	border-radius: 1.5rem;
	transition: filter 0.2s ease-in;

	&__link {
		min-height: 10rem;
		display: flex;
		text-decoration: none;
		height: 100%;
	}

	&__image {
		height: 12rem;

		&:focus {
			outline: none;
			opacity: 0.8;
		}
	}

	&__img {
		flex-shrink: 0;
		height: 100%;
		width: 15rem;
		object-fit: cover;
		border-top-left-radius: 1.5rem;
		border-bottom-left-radius: 1.5rem;
	}

	&__info {
		display: flex;
		flex-direction: column;
		padding-left: 1rem;
		padding-right: 0.4rem;
		width: 100%;
		overflow-wrap: break-word;
		background-color: $color-white;
		border-top-right-radius: 1.5rem;
		border-bottom-right-radius: 1.5rem;
	}

	&__heading-wrapper {
		margin-top: 0.4rem;
		margin-bottom: 0.6rem;
		display: flex;
		justify-content: space-between;
		gap: 0.5rem;
	}

	&__heading {
		display: block;
		font-size: $font-medium-3;
		text-align: left;
		margin: 0;
		font-weight: $font-weight-semibold;
		color: $color-primary;
		padding: 0;
		-webkit-line-clamp: 2;
		max-height:
			calc(
				1.6rem * 1.4 * 2
			);
		overflow: hidden;
		max-width: 7.8rem;
	}

	&__close-button {
		border: none;
		outline: none;
		background: none;
		cursor: pointer;
		flex-shrink: 0;
		align-self: self-start;
		padding-right: 0;
		padding-top: 0;
	}

	&__icon {
		width: 2.4rem;
		height: 2.4rem;
		fill: $color-primary;
		flex-shrink: 0;
	}

	&__rating {
		text-align: left;
		height: 1.1rem;
	}

	&__region {
		text-align: left;
		margin: auto 0 0.8rem;
		color: $color-dark-gray;
		font-size: $font-small-3;
		max-width: 12rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		@media (min-width: $breakpoint-verysmall) {
			max-width: 14rem;
			white-space: initial;
			overflow: initial;
			text-overflow: initial;
			margin: auto 0 0.8rem;
		}
	}
}

@media (min-width: $breakpoint-mobilelarge) {
	.favorited-hotel__heading {
		max-width: 13.3rem;
	}
}
@media (min-width: $breakpoint-verysmall) {
	.favorited-hotel {
		min-width: 35rem;

		&__img {
			width: 18rem;
		}

		&__heading {
			font-size: $font-medium-3;
			max-height: calc(1.8rem * 1.4 * 2); // font-size x line-height x number of lines
			max-width: fit-content;
		}
	}
}

@media (min-width: $breakpoint-small) {
	.favorited-hotel {
		width: 39rem;

		&__image {
			height: 12rem;
		}

		&__img {
			width: 18rem;
		}
	}
}

@media (min-width: $breakpoint-verylarge) {
	.favorited-hotel {
		&__overlay {
			display: none;
		}

		&__img {
			width: 18rem;
		}
	}
}

</style>
