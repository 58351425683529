<template>
	<div class="search-field">
		<template v-if="isMinTablet">
			<SearchSuggest
				:inline="false"
				icon="search"
			/>
		</template>
		<template v-else>
			<FormField
				class="search-field__field"
				:show-modal="showModal"
				:show-toggle-icon="false"
				:placeholder="'Website durchsuchen'"
				icon="search"
				@click="toggleModal"
				@FormField:Clear="close"
			/>

			<Modal
				v-model="showModal"
				class="search-field__modal"
				:initial-render="true"
				:title="'Website durchsuchen'"
				:offer-duration-modal="true"
				:with-footer="false"
				@Modal:Close="close"
			>
				<SearchSuggest
					ref="modalSearchSuggest"
					:inline="true"
					icon="search"
					:custom-clear-icon="'clearSearch'"
				/>
			</Modal>
		</template>
	</div>
</template>

<script lang="ts" setup>

import SearchSuggest from '@lmt-rpb/SearchSuggest/SearchSuggest.vue';
import FormField from '@lmt-rpb/FormField/FormField.vue';
import Modal from '@lmt-rpb/Modal/Modal.vue';
import {
	computed, nextTick, ref, onBeforeMount, onBeforeUnmount,
} from 'vue';

import { useStore } from '@/components/common/store';

const store = useStore();

const modalSearchSuggest = ref<InstanceType<typeof SearchSuggest> | null>(null);

const showModal = ref(false);

const isMinTablet = computed((): boolean => store.state.config.isMinTablet);

const toggleModal = (): void => {
	showModal.value = !showModal.value;

	nextTick(() => {
		modalSearchSuggest.value?.input?.focus();
	});
};

function close() {
	modalSearchSuggest.value?.clearSuggestAndClose();
	showModal.value = false;
}

onBeforeMount(() => {
	window.addEventListener('pageshow', close);
});

onBeforeUnmount(() => {
	window.removeEventListener('pageshow', close);
});

defineExpose({
	SearchSuggest,
	FormField,
	Modal,
	showModal
});

</script>

<style lang="scss" scoped>
:deep(.form-field__label .form-field__input) {
	font-size: $font-small-1;
	font-weight: $font-weight-bold;
	align-items: center;
}

:deep(.form-field__label) {
	margin-bottom: 0;
	width: auto;
}

:deep(.form-field__icon) {
	margin-left: 1rem;
}

:deep(.form-field) {
	height: 6.4rem;

	@media (min-width: $breakpoint-small) {
		height: 5.6rem;
	}
}

@mixin icons() {
	max-width: 1.4rem;
	max-height: 1.4rem;
	stroke-width: 1;
}

:deep(.modal){
	height: 100vh;
}

:deep(.modal__content) {
	position: relative;
	overflow: hidden !important;
	max-width: unset !important;
}

:deep(.modal.is-open) {
	.dropdown__box {
		margin-top: 0 !important;
	}

	.dropdown .dropdown__box {
		max-height: calc(100vh - 15rem) !important;

		@media (min-width: $breakpoint-verysmall) {
			max-height: calc(100vh - 18rem) !important;
		}
	}

	.modal__header {
		background-color: $color-modal-header-background !important;
		color: $color-white !important;

		svg > path {
			fill: $color-white;
		}

		padding: 0.8rem 1.8rem;
		max-height: 3.8rem;
		justify-content: unset;

		@media (min-width: $breakpoint-verysmall) {
			padding-left: 2.4rem !important;
		}

		.modal__close {
			height: unset;
			right: 1.3rem;

			svg {
				@include icons;

				stroke: $color-white;
			}
		}

		@media (min-width: $breakpoint-verysmall) {
			max-height: 5.7rem;
		}
	}

	.dropdown__container {
		padding: 1.6rem 0.8rem;

		@media (min-width: $breakpoint-verysmall) {
			padding-left: 1.7rem;
		}
	}

	.form-field {
		border-top: 0 !important;
		border-radius: 0 !important;
		max-height: 4rem !important;
		padding: 0.8rem 1.6rem 0.8rem 1.4rem !important;

		@media (min-width: $breakpoint-verysmall) {
			// padding left needs to be bit larger because of icon
			padding-left: 2.3rem !important;
		}

		&__icon {
			margin-left: unset;
		}

		&__clear-icon {
			@include icons;
		}
	}

	.search-suggest {
		&__item {
			padding: 0;

			&-link {
				height: 4.2rem;
				margin-bottom: 0.6rem;

				@media (min-width: $breakpoint-verysmall) {
					margin-bottom: 1.6rem;
				}
			}
		}
	}

	.modal__body {
		padding: 0 !important;
		overflow: hidden !important;
	}
}

.search-field {
	$loader-size: 3rem;

	@media (min-width: $breakpoint-small) {
		position: absolute;
	}

	@media (min-width: $breakpoint-extralarge) {
		position: fixed;
	}

	.modal__content .form-field {
		height: 4.5rem;
	}

	:deep(.form-field__icon) {
		flex-shrink: 0;
		width: 2.5rem;
		height: 2.5rem;
		fill: $color-primary;
	}

	.search-field__modal {
		:deep(.form-field) {
			height: 6.5rem !important;
			border-radius: 1rem;
		}

		:deep(.modal__header) {
			height: 7.5rem;
			color: $color-primary;
			background-color: $color-vivid;
			cursor: pointer;
		}

		:deep(.modal__body) {
			padding: 1.6rem 1rem 1rem;
		}

		:deep(.modal__title) {
			font-size: $font-small-1;

			@media (min-width: $breakpoint-verysmall) {
				font-size: $font-medium-3;
			}

			font-weight: $font-weight-bold;
		}

		:deep(.dropdown__inner) {
			border: none;
			max-height: 100%;
		}

		@media (min-width: $breakpoint-small) {
			:deep(.dropdown__inner) {
				min-width: 39rem;
			}
		}

		:deep(.search-suggest__dropdown) {
			.dropdown__field::after {
				display: none;
			}
		}
	}
}

</style>
