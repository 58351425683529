import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "loading__headlines"
}

import OfferGrid from '@lmt-rpb/OfferGrid/OfferGrid.vue';
import SkeletonLoader from '@lmt-rpb/SkeletonLoader/SkeletonLoader.vue';
import RoundedButton from '@lmt-rpb/RoundedButton/RoundedButton.vue';
import axios, { AxiosError } from 'axios';
import request from '@components/common/services/request';
import { determineClient, getLocaleString } from '@utils/utils';
import { HotelBoxData } from '@/interfaces/components/hotelBoxData';
import { HotelDeal, HotelDealsResponse } from '@/interfaces/api/v1-hotel-deals';
import { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';
import {
	computed, onMounted, Ref, ref,
} from 'vue';
import { transformList } from './helper';

interface Props {
	fromPlugin: boolean,
	isSlider: boolean,
	isFourColumns: boolean,
	apiUrl: string,
	uniqueKey: string,
	destinationName: string,
	moreLink: string,
	headerProps: HeaderProps,
	parentGrid: HTMLElement,
	hotelBoxAmount: string,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'DealGrid',
  props: {
    fromPlugin: { type: Boolean },
    isSlider: { type: Boolean },
    isFourColumns: { type: Boolean },
    apiUrl: {},
    uniqueKey: {},
    destinationName: { default: '' },
    moreLink: { default: '' },
    headerProps: {},
    parentGrid: {},
    hotelBoxAmount: { default: '' }
  },
  setup(__props: any) {

const props = __props;

const items : Ref<HotelBoxData[] | never[]> = ref([]);

const mqScroller: Ref<MediaQueryList | undefined> = ref();

const error = ref(false);

const loading = ref(true);

const moreDealsLoading = ref(false);

const skeletonType: Ref<'grid' | 'slider'> = ref('slider');

const currencySymbol = ref(getLocaleString('currency'));

const showSuns = ref(determineClient(window.location.href) === 'com');

const links: Ref<{ prev?: string; next?: string }> = ref({});

const numberOfSkeletons = computed((): number => parseInt(props.hotelBoxAmount, 10) || 3);

const showMoreButton = computed((): boolean => !!(links.value && links.value.next && items.value.length));

const checkIsScroller = (media: MediaQueryListEvent | MediaQueryList): void => {
	if ((props.isSlider && !media.matches) || !props.isSlider) {
		skeletonType.value = 'grid';
	} else {
		skeletonType.value = 'slider';
	}
};

const fetchOffers = (): void => {
	loading.value = true;
	error.value = false;

	request<HotelDealsResponse>({ method: 'get', url: props.apiUrl, }, props.uniqueKey)
		.then((resp) => {
			if (!resp?.Offers?.length) {
				props.parentGrid.remove(); // removing parent grid to remove whitespace due to min-height set on grid
				return;
			}

			items.value = transformList(resp.Offers as unknown as HotelDeal[], 'v1-hotel-deals');

			if (!props.isSlider) {
				links.value = resp?._links || {};
			}
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('DealGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			loading.value = false;
		});
};

const getMoreDeals = (): void => {
	if (props.isSlider) {
		return;
	}

	moreDealsLoading.value = true;
	error.value = false;

	request<HotelDealsResponse>({ method: 'get', url: links.value.next, }, props.uniqueKey)
		.then((resp) => {
			const newItems = transformList(resp.Offers as unknown as HotelDeal[], 'v1-hotel-deals');
			items.value = [...items.value, ...newItems];
			links.value = resp._links || {};
		})
		.catch((axiosError: AxiosError) => {
			if (!axios.isCancel(axiosError)) {
				console.error('DealGrid: ', axiosError);
			}

			error.value = true;
		})
		.finally(() => {
			moreDealsLoading.value = false;
		});
};

onMounted(() => {
	mqScroller.value = window.matchMedia('(max-width: 1024px)');
	mqScroller.value.addListener(checkIsScroller);

	fetchOffers();
	checkIsScroller(mqScroller.value);
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (!loading.value && !error.value && items.value.length)
      ? (_openBlock(), _createBlock(OfferGrid, {
          key: 0,
          "hotel-list": items.value,
          deal: true,
          slider: _ctx.isSlider,
          "currency-symbol": currencySymbol.value,
          "show-suns": showSuns.value,
          "header-props": _ctx.headerProps,
          "show-bottom-c-t-a": false,
          "more-link": _ctx.moreLink,
          "destination-name": _ctx.destinationName,
          "four-columns": _ctx.isFourColumns
        }, null, 8 /* PROPS */, ["hotel-list", "slider", "currency-symbol", "show-suns", "header-props", "more-link", "destination-name", "four-columns"]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(SkeletonLoader, {
            "width-unit": 'rem',
            "loader-width": 30,
            "loader-height": 7,
            "border-radius": '0.5rem',
            class: "loading__headline"
          })
        ]))
      : _createCommentVNode("v-if", true),
    (loading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(skeletonType.value === 'grid' ? 'loading__container-deal-list' : 'loading__container')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numberOfSkeletons.value, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(skeletonType.value === 'grid' ? 'loading__item-deal-list' : 'loading__item')
            }, [
              _createVNode(SkeletonLoader, {
                "width-unit": '%',
                "loader-width": 100,
                "loader-height": skeletonType.value === 'slider' ? 44.6 : 38,
                "border-radius": '0.5rem'
              }, null, 8 /* PROPS */, ["loader-height"])
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (moreDealsLoading.value && !error.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass(skeletonType.value === 'grid' ? 'loading__container-deal-list' : 'loading__container')
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(numberOfSkeletons.value, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(skeletonType.value === 'grid' ? 'loading__item-deal-list' : 'loading__item')
            }, [
              _createVNode(SkeletonLoader, {
                "width-unit": '%',
                "loader-width": 100,
                "loader-height": skeletonType.value === 'slider' ? 44.6 : 38,
                "border-radius": '0.5rem'
              }, null, 8 /* PROPS */, ["loader-height"])
            ], 2 /* CLASS */))
          }), 128 /* KEYED_FRAGMENT */))
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true),
    (!_ctx.isSlider)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: _normalizeClass(["deal-list__pager", {'deal-list__pager--no-next': !showMoreButton.value}])
        }, [
          (showMoreButton.value)
            ? (_openBlock(), _createBlock(RoundedButton, {
                key: 0,
                class: "deal-list__more-button",
                onClick: _cache[0] || (_cache[0] = _withModifiers(() => getMoreDeals(), ["prevent"]))
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Mehr Angebote laden ")
                ])),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}
}

})