import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = ["href", "data-ga-item_id"]
const _hoisted_2 = ["data-src", "alt"]
const _hoisted_3 = { class: "teaser__item-footer" }
const _hoisted_4 = { class: "teaser__item-name" }
const _hoisted_5 = {
  key: 0,
  class: "teaser__item-price"
}
const _hoisted_6 = { class: "teaser__item-price-formatted" }

import {
	Carousel, Slide, Navigation as CarouselNavigation,
} from 'vue3-carousel';
import { ObserveVisibility as vObserveVisibility } from 'vue-observe-visibility';
import { addTopMarginToFirstElement } from '@utils/utils';
import { formatPrice } from '@/js/utils/priceUtils';
import BaseIcon from '@lmt-rpb/BaseIcon/BaseIcon.vue';
import ComponentHeader from '@lmt-rpb/ComponentHeader/ComponentHeader.vue';
import { onMounted, onUpdated, ref } from 'vue';
import type { HeaderProps } from '@lmt-rpb/ComponentHeader/componentHeaderTypes';

export interface TeaserData {
	text: string;
	link: string;
	price: number;
	image: string;
}

interface Props {
	headerProps: HeaderProps,
	items: TeaserData[]
	currency?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Teaser',
  props: {
    headerProps: {},
    items: {},
    currency: { default: 'EUR' }
  },
  setup(__props: any) {



const teaserElement = ref<HTMLElement | null>(null);

const scroll = ref<typeof Carousel>();

const showLeftOverlay = ref(false);

const showRightOverlay = ref(true);

const isMouseDown = ref(false);

const isDragging = ref(false);

const carouselSettings = {
	itemsToShow: 1.25,
	itemsToScroll: 1,
	breakpoints: {
		544: {
			itemsToShow: 2.25,
			itemsToScroll: 1,
		},
		768: { // breakpoint-small
			itemsToShow: 3,
			itemsToScroll: 1,
		},
		1200: { // breakpoint-large
			itemsToShow: 4,
			itemsToScroll: 1.25,
			touchDrag: false,
		}
	}
};

const addMarginTop = (): void => {
	if (teaserElement.value) {
		addTopMarginToFirstElement(teaserElement.value);
	}
};

const onMouseDown = (): void => {
	isMouseDown.value = true;
};

const onMouseUp = (e: MouseEvent): void => {
	setTimeout(() => {
		e.preventDefault();
		e.stopPropagation();

		isMouseDown.value = false;
		isDragging.value = false;
	}, 300); // click tilt time
};

const onMouseMove = (): void => {
	if (!isMouseDown.value || isDragging.value) {
		return;
	}

	isDragging.value = true;
};

const visibilityChanged = (isVisible: boolean, entry: IntersectionObserverEntry): void => {
	if (entry.target.className.includes('teaser__first-element')) {
		showLeftOverlay.value = !isVisible;
	}

	if (entry.target.className.includes('teaser__last-element')) {
		showRightOverlay.value = !isVisible;
	}
};

onMounted(() => {
	// TODO: not working correctly because the search renders after this and then there is a layout shift
	addMarginTop();
});
onUpdated(() => {
	scroll.value?.updateSlideWidth();
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    ref_key: "teaserElement",
    ref: teaserElement,
    class: "teaser"
  }, [
    _createVNode(ComponentHeader, { "header-props": _ctx.headerProps }, null, 8 /* PROPS */, ["header-props"]),
    _createVNode(_unref(Carousel), _mergeProps({
      ref_key: "scroll",
      ref: scroll
    }, carouselSettings, {
      class: ["teaser__scroll", [{'teaser__scroll--left': showLeftOverlay.value}, {'teaser__scroll--right': showRightOverlay.value}]]
    }), {
      addons: _withCtx(() => [
        _createVNode(_unref(CarouselNavigation), null, {
          prev: _withCtx(() => [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "teaser__arai-label" }, "prev", -1 /* HOISTED */)),
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "teaser__prev-icon"
            })
          ]),
          next: _withCtx(() => [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "teaser__arai-label" }, "next", -1 /* HOISTED */)),
            _createVNode(BaseIcon, {
              name: "chevron",
              class: "teaser__next-icon"
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return _withDirectives((_openBlock(), _createBlock(_unref(Slide), {
            key: index,
            index: index,
            class: _normalizeClass([{'teaser__first-element': index == 0}, {'teaser__last-element': index == _ctx.items.length - 1}]),
            title: item.text
          }, {
            default: _withCtx(() => [
              _createElementVNode("a", {
                href: isDragging.value ? undefined : item.link,
                class: "teaser__item",
                "data-ga-event": "select_content",
                "data-ga-content_type": "Teaser Item",
                "data-ga-item_id": item.text
              }, [
                _createElementVNode("img", {
                  class: "teaser__item-image rpb_lazy",
                  "data-sizes": "auto",
                  "data-src": item.image,
                  src: "data:image/gif;base64,R0lGODdhEgAMAIABAPD2/////ywAAAAAEgAMAAACDYSPqcvtD6OctNqLVQEAOw==",
                  alt: item.text,
                  onMousedown: onMouseDown,
                  onMouseup: onMouseUp,
                  onMousemove: onMouseMove
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_2),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.text), 1 /* TEXT */),
                  (item.price)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                        _cache[0] || (_cache[0] = _createElementVNode("small", null, "ab ", -1 /* HOISTED */)),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_unref(formatPrice)(item.price, _ctx.currency)), 1 /* TEXT */)
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ], 8 /* PROPS */, _hoisted_1)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["index", "class", "title"])), [
            [_unref(vObserveVisibility), {
					callback: visibilityChanged,
					intersection: {
						threshold: 1,
					},
				}]
          ])
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 16 /* FULL_PROPS */, ["class"])
  ], 512 /* NEED_PATCH */))
}
}

})